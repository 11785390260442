import { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { setCookie } from 'nookies';
import Router from 'next/router';
import { toast } from 'react-toastify';
import { api } from '../services/apiClient';
import { useAuth } from '../contexts/AuthContext';

interface IModalProps {
  modalIsOpen: boolean;
  resetPasswordCPF: string;
  closeModal: Dispatch<SetStateAction<boolean>>;
}

export function AlertCPFAlreadyExists({ modalIsOpen, closeModal }: IModalProps) {
  const { resetPasswordCPF } = useAuth();
  const [status, setStatus] = useState('');
  const [maskedEmail, setMaskedEmail] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [step, setStep] = useState(1); 

  const handleNextStep = async () => {
    if (step === 1) {
      try {
        const response = await api.post('/users/password', {
          document: resetPasswordCPF,
        });

        if (response.status === 200) {
          if (response.data.maskedEmail) {
            const { maskedEmail } = response.data;
            setMaskedEmail(maskedEmail);
          }
          setStep(2); 
        } else {
          console.log('Erro ao solicitar reset de senha');
        }
      } catch (error: any) {
        console.error('Erro na solicitação:', error);
        setStatus('error');
      }
    } else if (step === 2) {
      try {
        const { data } = await api.post('auth', {
          email,
          password,
        });
        const { accessToken, refreshToken } = data;
    
        setCookie(undefined, 'meunascimento.token', accessToken, {
          maxAge: 60 * 60 * 24 * 30, // 30 days
          path: '/',
        });
    
        setCookie(undefined, 'meunascimento.refreshToken', refreshToken, {
          maxAge: 60 * 60 * 24 * 30, // 30 days
          path: '/',
        });
    
        api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    
        if (accessToken) {
          const response = await api.get('users/profile');
          const { email, id, name, roles, avatar, isVerified } = response.data;
          setUser({ email, id, name, roles, avatar, isVerified });
        }

        Router.push('/profile/user');
        closeModal(false);
      } catch (error: any) {
        toast.error('Senha incorreta, por favor tente novamente.', {
          style: { zIndex: 9999 }, 
        });
        setStatus('error');
      } finally {
        
      }
    }
  };

  const { setUser } = useAuth();

  const handleResetPassword = async () => {
    try {
      setStep(3);
    } catch (error: any) {
      console.error('Erro ao resetar senha:', error);
      setStatus('error');
    }
  };

  const handleCloseModalAndRedirect = () => {
    closeModal(false); 
    Router.push('/login'); 
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => closeModal(false)}
        ariaHideApp={false}
        style={{
          overlay: {
            width: '100%',
            zIndex: 9999999,
            margin: 'auto ',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            backgroundColor: '#FBF9ED',
            maxWidth: '400px',
            maxHeight: '400px',
            width: '80%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '15px',
            margin: '100px auto',
            padding: '10px',
          },
        }}
      >
        <CloseButton onClick={() => closeModal(false)}>X</CloseButton>
        <ContainerInformation>
          {step === 1 && (
            <>
              <h1>A Nossa Vaquinha pertence a plataforma <strong><a href="https://meunascimento.com.br/">Meu Nascimento</a></strong>, na qual você já possui conta cadastrada.</h1>

              <br /><br />
              <Buttons>
                <button onClick={handleNextStep}>Próximo</button>
              </Buttons>
            </>
          )}
          {step === 2 && (
            <>
              <h1>Nos campos de login a seguir, insira seu e-mail e senha:</h1>

              <CompletedResponseLogin>
                {maskedEmail}
              </CompletedResponseLogin>
              
              <InputContainer>
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <PasswordInputContainer>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <EyeIcon onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? '🙈' : '👁️'}
                  </EyeIcon>
                </PasswordInputContainer>
              </InputContainer>
              <Buttons>
                <button style={{ cursor: 'pointer' }} onClick={handleResetPassword}>Esqueci minha senha</button>
                <button style={{ cursor: 'pointer' }} onClick={handleNextStep}>Entrar em minha conta</button>
              </Buttons>
            </>
          )}
          {step === 3 && (
            <>
              <CompletedResponse>
              <h3>Recuperação de senha</h3>
                
                Email enviado para: <br /> {maskedEmail}
              </CompletedResponse>
                <Buttons>
                  <button onClick={handleCloseModalAndRedirect}>Concluir</button>
                </Buttons>
            </>
          )}
          
        </ContainerInformation>
      </Modal>
    </>
  );
}

const CompletedResponseLogin = styled.div`
  color: green;
  font-size: 15px;
  margin-bottom: 20px;
`;

const CompletedResponse = styled.div`
  color: green;
  font-size: 18px;
  margin: 20px;

  h3 {
    color: #757474;
    margin-bottom: 10px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 25px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #000;
`;

const ContainerInformation = styled.div`
  max-width: 758px;
  width: 90%;
  background-color: #FBF9ED;
  border-radius: 15px;

  strong {
    color: #5B5DB5;
  }

  text-align: center;

  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 18px;
    color: #757474;
    margin: 12px;
    text-align: start;
  }

  input {
    border: none;
    border: solid 1px #000;
    padding: 10px;
    font-size: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PasswordInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  input {
  width: 100%;
    padding-right: 40px; /* Espaço para o ícone do olho */
  }
`;

const EyeIcon = styled.span`
  position: absolute;
  right: 15px;
  top: 8px;
  font-size: 22px;
  cursor: pointer;
  user-select: none;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: #5B5DB5;
    padding: 10px;
    margin-right: 10px;
    color: #fff;
    border: none;
    border-radius: 7px;
    font-size: 15px;
  }

  button:hover {
    opacity: 0.9;
  }

`;

